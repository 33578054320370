import React, { useState, useEffect } from "react";
// React Datepicker Pakage
import DatePicker from "react-datepicker";
// React Datepicker Pakage Stylesheet
import "react-datepicker/dist/react-datepicker.css";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
/**
 * This component returns selected start and end dates from datepickers
 * @component
 */
const DateSelected = (props) => {
  const { t } = useTranslation();
  // alert State
  const [alertOn, setAlert] = useState(false);
  // start Date
  const [startDate, setStartDate] = useState(null);
  // End Date
  const [endDate, setEndDate] = useState(null);
  // Sorting State
  let [sorting, toggleSorting] = useState(false);
  // Sorting Button Class
  let [sortBtnFlip, toggleSortBtns] = useState("");
  // Filter Alignment Classes
  const filterAlign = i18n.dir() === "rtl" ? "float-right" : "float-left ";
  // Clear Filter Button Classes
  const clearFilterClasses =
    i18n.dir() === "rtl"
      ? "btn datePick-btn btn-theme"
      : "btn datePick-btn btn-theme";
  const sorBtnClasses = i18n.dir() === "rtl" ? " ml-0 mr-1" : " mr-0 ml-1";
  function range(start, end) {
    var ans = [];
    for (let i = start; i >= end; i--) {
      ans.push(i);
    }
    return ans;
  }
  /**
   * this fuction returns selected dates
   * @returns Selected Start Date and End date
   */
  function datePickerCustom() {
    // Get the value of the current day
    const today = new Date();
    // Set years array
    const years = range(today.getFullYear(), 2000);
    // Set months array
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    // Set date input classes directions
    let dateInputClasses =
      i18n.dir() === "rtl" ? "dateInput ml-5" : "dateInput";
    return (
      <React.Fragment>
        <div className="col-md-2 col-sm-6 col-xs-6">
          {/* Start date datepicker */}
          <DatePicker
            selected={startDate}
            className={dateInputClasses}
            placeholderText={t("Select Start Date")}
            onChange={(date) => setStartDate(date)}
            selectsStart
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div
                style={{
                  margin: 10,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  {"<"}
                </button>
                <select
                  value={date.getYear()}
                  onChange={({ target: { value } }) => changeYear(value)}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <select
                  value={months[date.getMonth()]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  {">"}
                </button>
              </div>
            )}
          />
        </div>
        <div className="col-md-2 col-sm-6 col-xs-6">
          {/* End date datepicker */}
          <DatePicker
            className={dateInputClasses}
            placeholderText={t("Select End Date")}
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div
                style={{
                  margin: 10,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  {"<"}
                </button>
                <select
                  value={date.getYear()}
                  onChange={({ target: { value } }) => changeYear(value)}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <select
                  value={months[date.getMonth()]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  {">"}
                </button>
              </div>
            )}
          />
        </div>
      </React.Fragment>
    );
  }
  /**
   * this fuction clears filters
   */
  function clearFilterBtn() {
    let clearFilter = props.cleared;
    setStartDate(null);
    setEndDate(null);
    clearFilter();
  }
  /**
   * this fuction shows the alert for 3 Secs
   */

  function showAlert() {
    setAlert(true);
    setTimeout(function () {
      setAlert(false);
    }, 3000);
  }
  /**
   * this fuction sends the selected ordering to the parent component
   */
  function sendSelectedOrder() {
    let getOrder = props.ordered;
    let reverse = props.projects;
    if (sorting == true) {
      getOrder(true);
      reverse(true);
    } else {
      getOrder(false);
      reverse(false);
    }
  }

  /**
   * this fuction sends the selected dates to the parent component
   */

  function sendSelectedDates() {
    let GetSelectedDates = props.clicked;
    const today = new Date();
    let Dates = "";
    if (
      startDate !== null &&
      startDate !== null &&
      startDate <= today &&
      endDate <= today &&
      startDate <= endDate
    ) {
      Dates = {
        startDate:
          "" +
          (startDate.getMonth() + 1) +
          "/" +
          startDate.getDate() +
          "/" +
          startDate.getFullYear(),
        endDate:
          "" +
          (endDate.getMonth() + 1) +
          "/" +
          endDate.getDate() +
          "/" +
          endDate.getFullYear(),
      };
      GetSelectedDates(Dates);
    }
    //
    else {
      showAlert();
    }
  }

  /**
   * this fuction sets the filtes button style classes
   * @returns Filter Button Classes
   */

  function filterBtnClasses() {
    let filterBtn =
      i18n.dir() === "rtl"
        ? "btn datePick-btn btn-theme-green ml-5 "
        : "btn datePick-btn btn-theme-green mr-5 ";
    // Check start and end date
    // Filter button is disabled untill the user enters dates
    if (startDate === null || endDate === null)
      filterBtn = filterBtn + "disabled";
    return filterBtn;
  }

  /**
   * this fuction sets the alert classes
   */

  function alertClasses() {
    let classes = "row fade-in ";
    // Alert div is shown when alerton state is true and vice versa
    if (alertOn === false) classes = "row fade-out d-none";
    return classes;
  }

  /**
   * this fuction hides alert div
   */

  function hideAlert() {
    // setting alerton state to false will hide the alert div
    setAlert(false);
  }

  /**
   * this fuction toggles sorting state and sort button classes and sendes the selected order to the parent component
   */

  function toggleSortBtn() {
    if (sorting === false) {
      // if sorting state is false it addes flip-v (flip vertical) class to sorting button
      toggleSortBtns("flip-v");
      sorting = true;
    } else {
      toggleSortBtns("");
      sorting = false;
    }
    // send the selected order to the parent component
    sendSelectedOrder();
  }

  return (
    <div className="container">
      <div className={alertClasses()}>
        <div className="col-md-12">
          <div className="alert alert-danger alert-dismissible }">
            <button type="button" className="close" onClick={hideAlert}>
              <span aria-hidden="true">&times;</span>
            </button>
            <p>{t("Please Choose Proper Dates")}</p>
          </div>
        </div>
      </div>
      <div className="row">
        {/* Datepickers */}
        {datePickerCustom()}
        {/* Filter Buttons */}
        <div className="col-md-6">
          <div className={"sm-mt-10 " + filterAlign}>
            <button className={filterBtnClasses()} onClick={sendSelectedDates}>
              {t("Filter")}
            </button>
            <button className={clearFilterClasses} onClick={clearFilterBtn}>
              {t("Clear Filter")}
            </button>
          </div>
        </div>
        {/* Sort Button */}
        <div className="col-md-2 col-sm-6 col-xs-6 sm-mt-10">
          <p className="d-inline">{t("Sort By Date")}</p>
          <button
            className={"sort-btn d-inline " + sorBtnClasses}
            onClick={toggleSortBtn}
          >
            <i
              className={"fa fa-sort-amount-desc flip-none " + sortBtnFlip}
            ></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DateSelected;
