import React, {Component} from "react";
import Header from "../sub_page_header";
import address from "../utils/address";
import i18n from "i18next";
import Axios from "axios";
import {submit_volunteer_data, logout} from "../../repository";
import {animateScroll as scroll} from "react-scroll";
import {withTranslation} from "react-i18next";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

/**
 * This Component diplay volunteer form
 * @component
 * @see http://sadagaat-uk.org/volunteerForm
 */
class VolunteerForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                email: "",
                name: "",
                gender: "FEMALE",
                dateOfBirth: "",
                phoneNumber: "",
                whatsPhone: "",
                liveInSudan: "",
                hasCommunityInCountry: "",
                state: "",
                city: "",
                locality: "",
                neighborhood: "",
                bloodGroup: "O+",
                educationLevel: "",
                studyField: "",
                university: "",
                job: "",
                jobTitle: "",
                volunteeredBefore: "",
                volunteeredPeriod: "",
                volunteeredProjects: "",
                lastContribution: "",
                heardAboutSadagaat: "",
                note: "",
                receiveEmails: "",
                volunteerTime: ""
            },
            // form: {
            //     name: "",
            //     gender: "",
            //     ageGroup: "15-25",
            //     phoneNumber: "",
            //     secondPhoneNumber: "",
            //     email: "",
            //     bloodGroup: "",
            //     educationLevel: "",
            //     languages: "",
            //     studyField: "",
            //     job: "",
            //     country: "",
            //     city: "",
            //     cityProjects: "",
            //     volunteeredBefore: "",
            //     heardAboutSadagaat: "",
            //     volunteerTime: "",
            //     vacationTime: "",
            //     countryProjects: "",
            //     volunteeredPeriod: "",
            //     volunteeredProjects: "",
            //     receiveEmails: "",
            //     liveInSudan: "yes",
            //     isSadagaatInYourCountry: "",
            //     state: "",
            //     university: "",
            //     currentlyWorking: "",
            //     anythingExtra: ""
            // },
            response: {
                message: "",
                styleClass: "",
            },
            dob: moment(),
            cover: {},

            /*there is some quistion not found in bakend and mobile app  */
        };
    }

    clearState = () => {
        this.setState({
            form: {
                ...this.state.form,
                email: "",
                name: "",
                gender: "FEMALE",
                dateOfBirth: "",
                phoneNumber: "",
                whatsPhone: "",
                liveInSudan: "",
                hasCommunityInCountry: "",
                state: "",
                city: "",
                locality: "",
                neighborhood: "",
                bloodGroup: "O+",
                educationLevel: "",
                studyField: "",
                university: "",
                job: "",
                jobTitle: "",
                volunteeredBefore: "",
                volunteeredPeriod: "",
                volunteeredProjects: "",
                lastContribution: "",
                heardAboutSadagaat: "",
                note: "",
                receiveEmails: "",
                volunteerTime: ""
            },
        });
    };

    /**
     * This function validate from and return custom message fill this field
     * @param {object} e event of input
     * @param {string} message that return from handleFormErrorMessage
     * @returns {'fill this field'} custom message
     * */

    handleFormErrorMessage = (e, message = "") => {
        const {t} = this.props;

        if (e.target.value === "") e.target.setCustomValidity(t("fill this field"));
        else e.target.setCustomValidity(message);
    };

    /**
     * this function set the value of input form in the state
     * @param {object} e  event from input field
     * @example name:'ahmed'
     */
    handleChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            },
        });
    };

    /**
     * This function pass form data  to api through submit_volunteer_data  function and recive mresponse (message)
     * @param {object} e  submit button
     *
     */

    handleSubmit = (e) => {
        e.preventDefault();
        submit_volunteer_data(this.state.form)
            .then((response) => {
                this.clearState();
                this.setState({
                    response: {
                        ...this.state.response,
                        message: "Your Form Submitted Successfully",
                        styleClass: "success-msg",
                    },
                });
            })

            .catch((err) => {
                // check message returned from API
                console.log("Error Volunteer Form");
                let message = "";
                console.log(err.message);
                // if (err.message === "Request failed with status code 401") {
                //     logout();
                //   message = "Please Login";
                // }
                if (err.message === "Network Error") message = "Network Error";
                else message = "Something Went Wrong Please Try Again";
                this.setState({
                    response: {
                        ...this.state.response,
                        message: message,
                        styleClass: "error-msg",
                    },
                });
            });
        scroll.scrollTo(70);
    };


    async componentDidMount() {
        const fetch = await window.fetch(`${address()}cover-image/VOLUNTEER2`, {
            headers: {"accept-language": `${i18n.language}`},
        });
        if (fetch.status == 500) {
            this.setState({cover: undefined});

        } else {
            this.setState({cover: fetch});
        }


    }

    async componentWillReceiveProps() {
        const fetch = await window.fetch(`${address()}cover-image/VOLUNTEER2`, {
            headers: {"accept-language": `${i18n.language}`},
        });
        if (fetch.status == 500) {
            this.setState({cover: undefined});

        } else {
            this.setState({cover: fetch});
        }

    }

    render() {
        const {t} = this.props;
        const cover = this.state.cover;
        const states = ["khartoum", "north_kordofan", "northern", "kassala",
            "blue_nile", "north_darfur", "south_darfur", "south_kordofan", "al_jazirah", "while_nile", "river_nile",
            "red_sea", "al_qadarif", "sennar", "west_darfur", "central_darfur", "east_darfur", "west_kordofan"];

        const educationLevels = ["literate", "primary", "secondary", "graduate", "post_graduate"];

        return (
            <React.Fragment>
                <div className="main-content">
                    {
                        (cover !== undefined)
                            ?
                            <section style={{
                                //backgroundImage: 'url(' + "https://images.wallpaperscraft.com/image/couple_mountains_travel_125490_1280x720.jpg"+ ')',
                                backgroundImage: 'url(' + `${address()}cover-image/VOLUNTEER2` + ')'

                            }} className=" inner-header divider parallax layer-overlay overlay-dark-6">
                                <div className="container pt-60 pb-60 "
                                >
                                    <div className="section-content">
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <h3 className="font-28 text-white">{t("Registration Form")} </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            :
                            <section
                                className="volunteer-bg-img inner-header divider parallax layer-overlay overlay-dark-6">
                                <div className="container pt-60 pb-60 "
                                >
                                    <div className="section-content">
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <h3 className="font-28 text-white">{t("Registration Form")} </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                    }
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 col-md-offset-1">
                                    <div className="tab-content">
                                        <div className="tab-pane active p-15" id="register-tab">
                                            <form
                                                id="reg-form"
                                                name="volunteer-form"
                                                className="register-form"
                                                onSubmit={this.handleSubmit}>
                                                <div className="icon-box mb-0 p-0">
                                                    <h4 className="text-gray pt-10 mt-0 mb-30">
                                                        {t("Fill Your Volunteering Form")}
                                                    </h4>
                                                </div>

                                                <div className={`${this.state.response.styleClass} bold`}
                                                    role="alert">
                                                    <p> {t(this.state.response.message)} </p>
                                                </div>

                                                <h4 className="text-gray mt-20">
                                                  {"- " + t("Personal Information")}
                                                </h4>
                                                <div className="row">
                                                    <div className="form-group required col-md-12">
                                                        <label className="control-label">{t("name")}</label>
                                                        <input
                                                            name="name"
                                                            className="form-control"
                                                            type="text"
                                                            onChange={this.handleChange}
                                                            pattern="^([A-Za-z\u0621-\u064A]+)\s([A-Za-z\u0621-\u064A]+)(\s[A-Za-z\u0621-\u064A]+)?(\s[A-Za-z\u0621-\u064A]+)?([A-Za-z\u0621-\u064A\s]+)?$"
                                                            title={t("Please enter your fullName")}
                                                            value={this.state.form.name}
                                                            required="required"
                                                            onInvalid={(e) =>
                                                                this.handleFormErrorMessage(
                                                                    e,
                                                                    t("Please enter your fullName")
                                                                )
                                                            }
                                                            onInput={function (e) {
                                                                e.target.setCustomValidity(t(""));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group required col-md-12">
                                                        <label htmlFor="" className="control-label">
                                                            {t("E-Mail")}
                                                        </label>
                                                        <input
                                                            id=""
                                                            name="email"
                                                            className="form-control"
                                                            type="text"
                                                            onChange={this.handleChange}
                                                            pattern="^([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-z]{2,8})(\.[a-z]{2,8})?$"
                                                            title={t("that email address is invalid")}
                                                            value={this.state.form.email}
                                                            required="required"
                                                            onInvalid={(e) =>
                                                                this.handleFormErrorMessage(
                                                                    e,
                                                                    t("that email address is invalid")
                                                                )
                                                            }
                                                            onInput={function (e) {
                                                                e.target.setCustomValidity(t(""));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group required col-md-12">
                                                        <label htmlFor="">{t("Date Of Birth")}</label>
                                                        <input
                                                            id="date"
                                                            name="dateOfBirth"
                                                            className="form-control"
                                                            type="date"
                                                            value={this.state.form.dateOfBirth}
                                                            onChange={this.handleChange}
                                                            max={moment().format("YYYY-MM-DD")}
                                                            onInvalid={(e) =>
                                                                this.handleFormErrorMessage(
                                                                    e,
                                                                    t("enter date no later than") +
                                                                    moment().format("YYYY-MM-DD"))
                                                            }
                                                            onInput={function (e) {
                                                                e.target.setCustomValidity(t(""));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group required col-md-6">
                                                        <label htmlFor="" className="control-label">
                                                            {t("Phone")}.
                                                        </label>
                                                        <small className="font-12 text-gray"> </small>

                                                        <input
                                                            name="phoneNumber"
                                                            className="form-control"
                                                            type="tel"
                                                            onChange={this.handleChange}
                                                            pattern="^(0[0-9]{9})|(00[0-9]{12})$"
                                                            title={t(
                                                                "Enter a valid phone number with 10 number or 14"
                                                            )}
                                                            value={this.state.form.phoneNumber}
                                                            required="required"
                                                            onInvalid={(e) =>
                                                                this.handleFormErrorMessage(
                                                                    e,
                                                                    t(
                                                                        "Enter a valid phone number with 10 number or 14"
                                                                    )
                                                                )
                                                            }
                                                            onInput={function (e) {
                                                                e.target.setCustomValidity(t(""));
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="form-group required col-md-6">
                                                        <label>{t("Whatsapp Phone Number")}</label>
                                                        <small className="font-12 text-gray"> </small>

                                                        <input
                                                            name="whatsPhone"
                                                            className="form-control"
                                                            type="tele"
                                                            onChange={this.handleChange}
                                                            value={this.state.form.whatsPhone}
                                                            pattern="^(0[0-9]{9})|(00[0-9]{12})$"
                                                            title={t(
                                                                "Enter a valid phone number with 10 number or 14"
                                                            )}
                                                            onInvalid={(e) =>
                                                                this.handleFormErrorMessage(
                                                                    e,
                                                                    t(
                                                                        "Enter a valid phone number with 10 number or 14"
                                                                    )
                                                                )
                                                            }
                                                            onInput={function (e) {
                                                                e.target.setCustomValidity(t(""));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group required col-md-6">
                                                        <label htmlFor="" className="control-label">
                                                            {t("Gender")}
                                                        </label>
                                                        <select
                                                            name="gender"
                                                            className="form-control float-right"
                                                            onChange={this.handleChange}
                                                            value={this.state.form.gender}
                                                            required="required">
                                                            <option name="gender">{t("FEMALE")}</option>
                                                            <option name="gender">{t("MALE")}</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group required col-md-6">
                                                        <label className="control-label">
                                                            {t("Blood Group")}
                                                        </label>

                                                        <select
                                                            name="bloodGroup"
                                                            className="form-control float-right"
                                                            value={this.state.form.bloodGroup}
                                                            onChange={this.handleChange}
                                                        >
                                                            <option>O+</option>
                                                            <option>O-</option>
                                                            <option>B+</option>
                                                            <option>B-</option>
                                                            <option>A+</option>
                                                            <option>A-</option>
                                                            <option>AB+</option>
                                                            <option>AB-</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <h4 className="text-gray mt-20">
                                                    {"- " + t("Residency Information")}
                                                </h4>
                                                <div className="row">
                                                    <div className="form-group required col-md-6">
                                                        <label>
                                                            {t("Do you live in Sudan?")}
                                                        </label>
                                                        <div className="form-check- form-inline">
                                                            <div className="radio-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="liveInSudan"
                                                                    id="1"
                                                                    value="true"
                                                                    onChange={this.handleChange}
                                                                    checked={this.state.form.liveInSudan === "true"}
                                                                />
                                                                <label className="form-check-label" htmlFor="1">
                                                                    {t("Yes")}
                                                                </label>
                                                            </div>
                                                            <div className="radio-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="liveInSudan"
                                                                    value="false"
                                                                    onChange={this.handleChange}
                                                                    checked={this.state.form.liveInSudan === "false"}
                                                                />
                                                                <label className="form-check-label" htmlFor="">
                                                                    {t("No")}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {this.state.form.liveInSudan === "false" ?
                                                        <div className="form-group required col-md-6">
                                                            <label>
                                                                {t("does_sadagaat_has_a_community_in_your_country")}
                                                            </label>
                                                            <div className="form-check-input form-inline">
                                                                <div className="radio-inline">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="hasCommunityInCountry"
                                                                        id="1"
                                                                        value="true"
                                                                        onChange={this.handleChange}
                                                                        checked={this.state.form.hasCommunityInCountry === "true"}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="1">
                                                                        {t("Yes")}
                                                                    </label>
                                                                </div>
                                                                <div className="radio-inline">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="hasCommunityInCountry"
                                                                        value="false"
                                                                        onChange={this.handleChange}
                                                                        checked={this.state.form.hasCommunityInCountry === "false"}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="">
                                                                        {t("No")}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div> :
                                                        <>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="" className="control-label">
                                                                    {t("state")}
                                                                </label>
                                                                <select
                                                                    name="state"
                                                                    className="form-control float-right"
                                                                    onChange={this.handleChange}
                                                                    value={this.state.form.state}>
                                                                    {states.map((state, index) => {
                                                                        return <option key={index}>{t(state)}</option>
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label>{t("city")}</label>
                                                                <input
                                                                    name="city"
                                                                    className="form-control"
                                                                    type="text"
                                                                    onChange={this.handleChange}
                                                                    value={this.state.form.city}
                                                                    onInvalid={(e) =>
                                                                        this.handleFormErrorMessage(e, t("your value must not be white space"))}
                                                                    onInput={function (e) {e.target.setCustomValidity(t("")); }}
                                                                />
                                                            </div>
                                                            <div className="form-group required col-md-6">
                                                                <label>{t("locality")}</label>
                                                                <input
                                                                    name="locality"
                                                                    className="form-control"
                                                                    type="text"
                                                                    onChange={this.handleChange}
                                                                    value={this.state.form.locality}
                                                                    onInvalid={(e) =>
                                                                        this.handleFormErrorMessage(e, t("your value must not be white space"))}
                                                                    onInput={function (e) {e.target.setCustomValidity(t("")); }}
                                                                />
                                                            </div>
                                                            <div className="form-group required col-md-6">
                                                                <label>{t("neighborhood")}</label>
                                                                <input
                                                                    name="neighborhood"
                                                                    className="form-control"
                                                                    type="text"
                                                                    onChange={this.handleChange}
                                                                    value={this.state.form.neighborhood}
                                                                    onInvalid={(e) =>
                                                                        this.handleFormErrorMessage(e, t("your value must not be white space"))}
                                                                    onInput={function (e) {e.target.setCustomValidity(t("")); }}
                                                                />
                                                            </div>
                                                        </>}
                                                </div>

                                                <h4 className="text-gray mt-20">
                                                    {"- " + t("Work and Education")}
                                                </h4>
                                                <div className="row">
                                                    <div className="form-group required col-md-6">
                                                        <label>{t("Educational Level")}</label>
                                                        <select
                                                            name="educationLevel"
                                                            className="form-control float-right"
                                                            value={this.state.form.educationLevel}
                                                            onChange={this.handleChange}>
                                                            {educationLevels.map((level, index) => {
                                                                return <option value={level} key={index}>{t(level)}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="form-group required col-md-6">
                                                        <label>
                                                            {t("What did you Study(or are studding)?")}
                                                        </label>
                                                        <input
                                                            name="studyField"
                                                            className="form-control"
                                                            type="text"
                                                            pattern="^[^\s].+[^\s]$"
                                                            value={this.state.form.studyField}
                                                            onChange={this.handleChange}
                                                            onInvalid={(e) =>
                                                                this.handleFormErrorMessage(
                                                                    e,
                                                                    t("your value must not be white space")
                                                                )
                                                            }
                                                            onInput={function (e) {
                                                                e.target.setCustomValidity(t(""));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group required col-md-6">
                                                        <label>
                                                            {t("What is the name of your university?")}
                                                        </label>
                                                        <input
                                                            name="university"
                                                            className="form-control"
                                                            type="text"
                                                            pattern="^[^\s].+[^\s]$"
                                                            value={this.state.form.university}
                                                            onChange={this.handleChange}
                                                            onInvalid={(e) =>
                                                                this.handleFormErrorMessage(
                                                                    e,
                                                                    t("your value must not be white space")
                                                                )
                                                            }
                                                            onInput={function (e) {
                                                                e.target.setCustomValidity(t(""));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group required col-md-6">
                                                        <label>
                                                            {t("Do you currently work")}
                                                        </label>
                                                        <div className="form-check- form-inline">
                                                            <div className="radio-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="job"
                                                                    id="1"
                                                                    value="true"
                                                                    onChange={this.handleChange}
                                                                    checked={this.state.form.job === "true"}
                                                                />
                                                                <label className="form-check-label" htmlFor="1">
                                                                    {t("Yes")}
                                                                </label>
                                                            </div>
                                                            <div className="radio-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="job"
                                                                    value="false"
                                                                    onChange={this.handleChange}
                                                                    checked={this.state.form.job === "false"}
                                                                />
                                                                <label className="form-check-label" htmlFor="">
                                                                    {t("No")}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.form.job === "true" &&
                                                    <div className="row">
                                                        <div className="form-group required col-md-6">
                                                            <label>{t("whats_your_job")}</label>
                                                            <select
                                                                name="jobTitle"
                                                                className="form-control float-right"
                                                                value={this.state.form.jobTitle}
                                                                onChange={this.handleChange}>
                                                                {["private_sector", "public_sector", "free_business"]
                                                                    .map((sec, index) => {
                                                                        return <option value={sec} key={index}>{t(sec)}</option>
                                                                    })}
                                                            </select>
                                                        </div>
                                                    </div>}

                                                <h4 className="text-gray mt-20">
                                                    {"- " + t("About") + " " + t("Sadagaat")}
                                                </h4>
                                                <div className="row">
                                                    <div className="form-group required col-md-6">
                                                        <label>
                                                            {t("did_you_volunteer_in_sadagaat_charity_organization_before")}
                                                        </label>
                                                        <div className="form-check- form-inline">
                                                            <div className="radio-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="volunteeredBefore"
                                                                    id="1"
                                                                    value="true"
                                                                    onChange={this.handleChange}
                                                                    checked={this.state.form.volunteeredBefore === "true"}
                                                                />
                                                                <label className="form-check-label" htmlFor="1">
                                                                    {t("Yes")}
                                                                </label>
                                                            </div>
                                                            <div className="radio-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="volunteeredBefore"
                                                                    value="false"
                                                                    onChange={this.handleChange}
                                                                    checked={this.state.form.volunteeredBefore === "false"}
                                                                />
                                                                <label className="form-check-label" htmlFor="">
                                                                    {t("No")}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.form.volunteeredBefore === "true" &&
                                                    <div className="row">
                                                        <div className="form-group required col-md-6">
                                                            <label>{t("since_when")}</label>
                                                            <select
                                                                name="volunteeredPeriod"
                                                                className="form-control float-right"
                                                                value={this.state.form.volunteeredPeriod}
                                                                onChange={this.handleChange}>
                                                                <option value="before_2014">{t("before_2014")}</option>
                                                                {t("from_2014_to_2023").split(" ")
                                                                    .map((year, index) => <option value={year} key={index}>{year}</option>)}
                                                            </select>
                                                        </div>
                                                        <div className="form-group required col-md-6">
                                                            <label>
                                                                {t("what_projects_did_you_participate_in_with_sadagaat")}
                                                            </label>
                                                            <select
                                                                name="volunteeredProjects"
                                                                className="form-control float-right"
                                                                value={this.state.form.volunteeredProjects}
                                                                onChange={this.handleChange}>
                                                                {["Health Sector", "Water Sector", "Education Sector", "Feeding Sector"]
                                                                    .map((sec, index) => <option value={sec} key={index}>{t(sec)}</option>)}
                                                            </select>
                                                        </div>
                                                        <div className="form-group required col-md-6">
                                                            <label>
                                                                {t("when_was_your_last_contribute_in_sadagaat_voluntary_work")}
                                                            </label>
                                                            <select
                                                                name="lastContribution"
                                                                className="form-control float-right"
                                                                value={this.state.form.lastContribution}
                                                                onChange={this.handleChange}>
                                                                <option value="less_then_year">{t("less_then_year")}</option>
                                                                <option value="more_then_year">{t("more_then_year")}</option>
                                                            </select>
                                                        </div>
                                                    </div>}
                                                <div className="row">
                                                    <div className="form-group required col-md-6">
                                                        <label>
                                                            {t("from_where_did_you_hear_about_sadagaat")}
                                                        </label>
                                                        <div className="form-check- form-inline">
                                                            <div className="radio-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="heardAboutSadagaat"
                                                                    value="social_media"
                                                                    onChange={this.handleChange}
                                                                    checked={this.state.form.heardAboutSadagaat === "social_media"}
                                                                />
                                                                <label className="form-check-label" htmlFor="1">
                                                                    {t("Social Media")}
                                                                </label>
                                                            </div>
                                                            <div className="radio-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="heardAboutSadagaat"
                                                                    value="friends_or_family"
                                                                    onChange={this.handleChange}
                                                                    checked={this.state.form.heardAboutSadagaat === "friends_or_family"}
                                                                />
                                                                <label className="form-check-label" htmlFor="">
                                                                    {t("friends_or_family")}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group required col-md-6">
                                                        <label>
                                                            {t("which_time_suits_you_to_voluntary")}
                                                        </label>
                                                        <div className="form-check- form-inline">
                                                            <div className="radio-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="volunteerTime"
                                                                    value="morning"
                                                                    onChange={this.handleChange}
                                                                    checked={this.state.form.volunteerTime === "morning"}
                                                                />
                                                                <label className="form-check-label" htmlFor="1">
                                                                    {t("morning")}
                                                                </label>
                                                            </div>
                                                            <div className="radio-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="volunteerTime"
                                                                    value="evening"
                                                                    onChange={this.handleChange}
                                                                    checked={this.state.form.volunteerTime === "evening"}
                                                                />
                                                                <label className="form-check-label" htmlFor="">
                                                                    {t("evening")}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group required col-md-6">
                                                        <label>
                                                            {t("would_you_like_to_get_newsletter_of_Sadagaat_in_your_email")}
                                                        </label>
                                                        <div className="form-check- form-inline">
                                                            <div className="radio-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="receiveEmails"
                                                                    value="true"
                                                                    onChange={this.handleChange}
                                                                    checked={this.state.form.receiveEmails === "true"}
                                                                />
                                                                <label className="form-check-label" htmlFor="1">
                                                                    {t("Yes")}
                                                                </label>
                                                            </div>
                                                            <div className="radio-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="receiveEmails"
                                                                    value="false"
                                                                    onChange={this.handleChange}
                                                                    checked={this.state.form.receiveEmails === "false"}
                                                                />
                                                                <label className="form-check-label" htmlFor="">
                                                                    {t("No")}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-md-12">
                                                        <label htmlFor="note" className="control-label">
                                                            {t("do_you_have_anything_to_tell_us")}
                                                        </label>
                                                        <textarea
                                                            id="note"
                                                            name="note"
                                                            className="form-control"
                                                            onChange={this.handleChange}
                                                            value={this.state.form.note}
                                                            onInput={function (e) {
                                                                e.target.setCustomValidity(t(""));
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="form-group col-md-12">
                                                        <button className="btn text-white btn-theme-green btn-lg btn-block mt-15"
                                                            type="submit">
                                                            <span className="spinner-border spinner-border-sm"
                                                                role="status"
                                                                aria-hidden="true"/>
                                                            {t("Submit")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </React.Fragment>
        );
    }
}

export default withTranslation()(VolunteerForm);
