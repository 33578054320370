import { css } from "jquery";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
// const Testing = () => {
//   const { t } = useTranslation();
//   return <p>{t("Hello")}</p>;
// };

// export default Testing;
class Testing extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { t } = this.props;
    return <p>{t("Hello")}</p>;
  }
}

export default withTranslation(Testing);
