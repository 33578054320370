import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import address from "../utils/address";

function TopBar() {
    const {t, i18n} = useTranslation();
    const [contact, setContact] = useState({})
    const searchDir = i18n.dir() === "rtl" ? "pl-0 ml-20" : "pr-0 mr-20";
    const inputDir = i18n.dir() === "rtl" ? "float-right" : "float-left";
    const btnDir = i18n.dir() === "rtl" ? "float-left btnRight " : "float-right btnLeft ";

    async function fetchContact() {
        const fetcher = await window.fetch(`${address()}contact-info/UK`, {
            headers: {'accept-language': `${i18n.language}`}
        });
        const response = await fetcher.json();
        setContact(response);
    }

    useEffect(() => {
        fetchContact();
    }, [i18n.language])


    function setSearchQuery() {
        localStorage.setItem("searchQuery", document.getElementById("searchQuery").value);
        console.log(localStorage.getItem("searchQuery"));
    }

    function redirectSearchPage() {
        setSearchQuery();
        window.location.pathname = "/Search-Results";
    }

    function enterPressListener() {
        let searchInput = document.getElementById("searchQuery");
        console.log(searchInput);
        searchInput.addEventListener("keypress", function (e) {
            console.log(e);
            if (searchInput.value !== "" && e.code === "Enter") {
                e.preventDefault();
                redirectSearchPage();
            }
        });
    }

    return (
        <div className="bg-header-mask bg-img-cover sm-text-center">
            <div className="container">
                <div>
                    <div className="col-md-4 col-sm-4 no-border p-0">
                        <ul className="styled-icons icon-sm sm-text-center">
                            <li>
                                <a href={contact.facebook ?? "https://web.facebook.com/Sadagaat/?_rdc=1&_rdr"}
                                   target="blank">
                                    <i className="fa fa-facebook text-white"></i>
                                </a>
                            </li>
                            <li>
                                <a href={contact.twitter ?? "http://twitter.com/sadagaat"}
                                   target="blank">
                                    <i className="fa fa-twitter text-white"></i>
                                </a>
                            </li>
                            <li>
                                <a href={contact.linkedin ?? "http://www.linkedin.com/pub/sadagaat-sudan/54/431/5b7"}
                                   target="blank">
                                    <i className="fa fa-linkedin text-white"></i>
                                </a>
                            </li>
                            <li>
                                <a href={contact.youtube ?? "https://www.youtube.com/channel/UCRSvaVpv6Hw6eUnI6OsutSQ"}
                                   target="blank">
                                    <i className="fa fa-youtube-play text-white"></i>
                                </a>
                            </li>
                            <li>
                                <a href={contact.instagram ?? "https://www.instagram.com/sadagaat_organization/"}
                                   target="blank">
                                    <i className="fa fa-instagram text-white"></i>
                                </a>
                            </li>
                            <li>
                                <a href={contact.soundcloud ?? "https://soundcloud.com/sadagaat"}
                                   target="blank">
                                    <i className="fa fa-soundcloud text-white"></i>
                                </a>
                            </li>
                            <li>
                                <a href={contact.tiktok ?? "http://www.tiktok.com/@sadagaatsudan"}
                                   target="blank">
                                    <i className="text-white">
                                        <svg style={{ width: 12, height: 12 }}
                                             fill="currentColor"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path fillRule="evenodd" d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/>
                                        </svg>
                                    </i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div className="col-md-5 col-sm-5 m-0 text-center">
                        <ul className="list-inline flip sm-pull-none mt-15">
                            <li className="py-10 mr-10">
                                <i className="fa fa-phone text-white">
                                    <span className="pl-5 text-white">
                                      {`+${contact.phone ?? "44884060063"}`}
                                    </span>
                                </i>
                            </li>
                            <li className="py-10">
                                <i className="fa fa-envelope-o text-white">
                                    <span className="pl-5 text-white">
                                        {contact.email ?? "info@sadagaat-uk.org"}
                                    </span>
                                </i>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-3 col-sm-3 sm-mt-10">
                    <div className={"mt-20 no-border " + searchDir}>
                        <form className="search-bar" onSubmit={redirectSearchPage}>
                            <input
                                name="search"
                                id="searchQuery"
                                placeholder={t("Search in Projects")}
                                className={"form-control d-inline " + inputDir}
                                type="text"
                                required="required"
                                onFocus={enterPressListener}/>

                            <Link
                                to="/Search-Results"
                                className={"btn text-theme d-inline " + btnDir}
                                onClick={setSearchQuery}>
                                <i className="fa fa-search"></i>
                            </Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopBar;
