import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import AppRoutes from "./routes";
import ReactGA from "react-ga";
import "./App.css"

function App(props) {
    const {i18n} = useTranslation();
    document.getElementById("direction").dir = i18n.dir();
    // Google analytics tracking
    const TrackinID = "UA-188844506-1";
    ReactGA.initialize(TrackinID);
    ReactGA.pageview(window.location.pathname + window.location.search);

    useEffect(() => {
        if (i18n.dir() === "rtl") {
            document.getElementById("bootstrp-file").href =
                "css/bootstrap-rtl.min.css";

            document.getElementById("main-rtl").href = "css/style-main-rtl.css";

            document.getElementById("main-rtl-extra").href =
                "css/style-main-rtl-extra.css";
        } else if (i18n.dir() === "ltr") {
            document.getElementById("bootstrp-file").href = "";
            document.getElementById("main-rtl").href = "";
            document.getElementById("main-rtl-extra").href = "";
        }
    }, i18n.language);

    return <AppRoutes/>;
}

export default App;
